<template>
    <div class="detail_div">
        <div class="bill_div back_div" id="logistics-detail-top">
            <div class="billNo_div">
                <p class="title">
                    {{ showTab === "SZ" ? "S/0号" : "提单号" }}：
                </p>
                <p class="small_title">
                    【{{ boxInfo ? boxInfo.blno || boxInfo.billNo : "" }}】 【{{
                        boxInfo ? boxInfo.remark : ""
                    }}
                    <a @click="addRemark()">
                        {{
                            boxInfo && boxInfo.remark ? "修改备注" : "添加备注"
                        }}
                    </a>
                    】
                </p>
            </div>
            <el-divider></el-divider>
            <div class="billNo_info_div">
                <!-- 提单信息 -->
                <DetailContentBlon
                    :showTab="showTab"
                    :activePosition="Number(activePosition)"
                    :boxInfo="boxInfo"
                    :headers="headers"
                />
                <!-- 船期信息 -->
                <DetailContentShip
                    :showTab="showTab"
                    :activePosition="Number(activePosition)"
                    :boatInfo="boatInfo"
                    :shipInfo="shipInfo"
                    :boxInfo="boxInfo"
                    @confirm="refreshAfterChange"
                />
            </div>
        </div>
        <!-- tab切换 -->
        <div id="logistics-detail-box">
            <el-tabs
                v-model="activePosition"
                type="card"
                @tab-click="handleClick"
            >
                <el-tab-pane
                    :key="index"
                    v-for="(item, index) in nppinfoVo"
                    :label="item.ctnno"
                    :name="index + ''"
                ></el-tab-pane>
            </el-tabs>
        </div>
        <!-- 集装箱信息 -->
        <DetailContentBox
            :showTab="showTab"
            :activePosition="Number(activePosition)"
            :boatInfo="boatInfo"
            :boxInfo="boxInfo"
            :headers="headers"
            :errorFlag="errorFlag"
        />
        <!-- 预配舱单 -->
        <DetailContentManifest
            :activePosition="Number(activePosition)"
            :boxInfo="boxInfo"
            :errorFlag="errorFlag"
            :showTab="showTab"
            v-if="showTab !== 'SZ'"
        />
        <DetailContentCustomRelease
            v-if="!['QD', 'TJ', 'SZ'].includes(showTab)"
            :activePosition="Number(activePosition)"
            :boxInfo="boxInfo"
            :showTab="showTab"
        ></DetailContentCustomRelease>
        <!-- 修改备注弹窗 -->
        <remark-box-dialog
            v-if="remarkDialogVisible"
            ref="RemarkBoxDialog"
            @refreshDataList="refreshAfterChange"
            :showTab="showTab"
        ></remark-box-dialog>
    </div>
</template>

<script>
import RemarkBoxDialog from "../RemarkDialog";
import { checkInfoList1 } from "@/utils/dataList";
import { detailContentShow } from "./detailContentShow";
import DetailContentBlon from "./DetailContentBlon.vue";
import DetailContentShip from "./DetailContentShip.vue";
import DetailContentBox from "./DetailContentBox.vue";
import DetailContentManifest from "./DetailContentManifest.vue";
import DetailContentCustomRelease from "./DetailContentCustomRelease.vue";
import { logisticsRequest } from "@/views/Logistics/logistics";
import {
    apiCallback,
    wharfCodeToStr,
    passFlag2String,
    splitDateAndTime,
    getString2JsonArr,
    passFlag2StringXM,
} from "@/utils";
export default {
    props: {
        queryData: {
            type: Object,
            default: {},
        },
        showTab: {
            type: String,
        },
    },
    data() {
        return {
            storage: "--",
            test: "测试信息",
            remarkDialogVisible: false,
            list: [],
            loadingView: "", //全屏Loading
            icon_green: require("@/assets/img/green.png"),
            icon_red: require("@/assets/img/red.png"),
            boxList: [],
            boxInfo: {},
            boatInfo: {},
            shipInfo: [],
            nppInGateEmptyVoList: [],
            ctnEmptyVoList: [],
            nppinfoVo: [],
            activePosition: 0,
            errorFlag: 0, //0:毛重误差未超过5%， 1:超过
            headers: JSON.parse(
                JSON.stringify(
                    detailContentShow({
                        tab: this.showTab,
                        type: 1,
                    }),
                ),
            ),
            // 集卡信息
            takeoutList: [],
            passFlag2String,
            splitDateAndTime,
        };
    },
    components: {
        RemarkBoxDialog,
        DetailContentBlon,
        DetailContentShip,
        DetailContentBox,
        DetailContentManifest,
        DetailContentCustomRelease,
    },
    computed: {},

    mounted() {
        const queryData = this.queryData.id
            ? this.queryData
            : this.$route.query;
        if (queryData.id) {
            this.Init(queryData);
        }
    },
    destroyed() {},
    methods: {
        Init(list = this.list) {
            this.boxList = [];
            this.boxInfo = {};
            this.boatInfo = {};
            this.shipInfo = [];
            this.nppInGateEmptyVoList = [];
            this.ctnEmptyVoList = [];
            this.nppinfoVo = [];
            this.activePosition = "0";
            this.errorFlag = 0; //0:毛重误差未超过5%， 1:超过
            this.checkInfoList = JSON.parse(JSON.stringify(checkInfoList1));
            // 防止下面赋值报错
            this.headers = JSON.parse(
                JSON.stringify({
                    ...detailContentShow({
                        tab: "NB",
                        type: 1,
                    }),
                    ...detailContentShow({
                        tab: "SH",
                        type: 1,
                    }),
                    ...detailContentShow({
                        tab: "QD",
                        type: 1,
                    }),
                    ...detailContentShow({
                        tab: "TJ",
                        type: 1,
                    }),
                    ...detailContentShow({
                        tab: "XM",
                        type: 1,
                    }),
                    ...detailContentShow({
                        tab: "SZ",
                        type: 1,
                    }),
                }),
            );
            this.list = list;
            const id = list.id;
            this.takeoutList = [];
            this.openFullScreen2();
            this.getBoxInfo(id);
        },

        //全屏显示加载
        openFullScreen2() {
            this.loadingView = this.$loading({
                lock: true,
                text: "实时查询中...",
            });
        },

        //修改备注
        addRemark() {
            this.remarkDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.RemarkBoxDialog.init({
                    id: this.list.id,
                    blno: this.list.blno,
                    remark: this.boxInfo.remark,
                });
            });
        },

        //修改备注后刷新参数和页面
        refreshAfterChange() {
            this.$emit("change");
            this.Init(this.list);
        },
        // 切换tab
        handleClick(tab) {
            this.activePosition = tab.index;
            this.setBoxInfo(this.activePosition);
        },

        getBoxInfo(id) {
            var that = this;
            // 获取请求详情接口
            const reqFunc = logisticsRequest({
                tab: this.showTab,
                type: 7,
            });
            reqFunc({ id })
                .then((response) => {
                    const data = apiCallback(response);
                    that.showDetail(data);
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    if (that.loadingView) {
                        that.loadingView.close();
                    }
                });
        },
        async showDetail(detail) {
            if (this.showTab === "SZ") {
                console.log("detail", detail);
                this.boxInfo = {
                    ...detail.blno,
                    // wharf:detail
                    vesselsName: detail.blno.vessel,
                };
                this.nppinfoVo = detail.ctn || [];

                this.boatInfo = {
                    ...detail.blno,
                    blno: detail.blno.billNo,
                    vesselEname: detail.blno.vessel,
                    wharf: detail.blno.giName||detail.blno.loadingPort,
                };

                this.shipInfo = [detail.blno];
                this.showNppTabs(this.activePosition);
            } else {
                var that = this;
                that.boxInfo = detail;
                if (this.showTab === "NB") {
                    that.nppInGateEmptyVoList =
                        that.boxInfo?.nppInGateEmptyVoList || [];
                } else {
                    that.ctnEmptyVoList = that.boxInfo?.ctnEmptyVoList || [];
                }
                that.nppinfoVo = [];
                if (this.showTab === "NB") {
                    for (var i = 0; i < that.nppInGateEmptyVoList.length; i++) {
                        var temp = that.nppInGateEmptyVoList[i].ctn;
                        if (temp) {
                            that.nppinfoVo.push(temp);
                        }
                    }
                } else {
                    for (var i = 0; i < that.ctnEmptyVoList.length; i++) {
                        var temp = that.ctnEmptyVoList[i].ctn;
                        if (temp) {
                            that.nppinfoVo.push(temp);
                        }
                    }
                }

                //船期时间,有船期先显示船期信息，如果有进港则显示进港的船期信息
                if (detail.vessels) {
                    that.shipInfo = [detail.vessels];
                    that.boatInfo = {
                        wharf: wharfCodeToStr(detail.vessels.wharf),
                        vesselEname: detail.vessels.vesselsName,
                        voyage: detail.vessels.voyage,
                    };
                }

                that.showNppTabs(that.activePosition);
                that.showTotalNum();
            }
        },
        //显示nppinfo的选项卡
        showNppTabs(position) {
            this.activePosition = position;
            this.setBoxInfo(position);
        },
        //数据整理并展示
        setBoxInfo(position) {
            //设置箱动态数据
            if (this.showTab === "NB") {
                var box = this.boxInfo.nppInGateEmptyVoList[position];
            } else if (this.showTab === "SZ") {
                var box = this.nppinfoVo[position] || {};
                const key = Object.keys(this.headers);
                key.forEach((item) => {
                    this.headers[item].prop = box[item]||'';
                });
            } else {
                var box = this.boxInfo.ctnEmptyVoList[position];
            }
            if (box && box.ctn) {
                const {
                    ctnno,
                    sealno,
                    dlPortCode,
                    type,
                    ctnOwner,
                    status,
                    bills,
                    grossWeight,
                    inGateTime,
                    loadTime,
                    truckLicense,
                    costcoTime,
                    size,
                    measure,
                    weight,
                    packageNum,
                    receiveTime,
                    cpcodeFlag,
                    passFlag,
                    volume,
                    packno,
                    dischargePort,
                    loadPort,
                    portPassFlag,
                    inPortTime,
                    totalWeight,
                    outPortTime,
                    backTime,
                    createTime,
                    inputTime,
                    cargoTime,
                    blnoMain,
                    isPortPass,
                    portPassTime,
                    customPassTime,
                    isCustomPass,
                    outGateTime,
                    vgm,
                } = box.ctn;
                // console.log(box.ctn.portPassFlag,"box.ctn");
                this.headers.vgm.prop = vgm; // 厦门VGM
                this.headers.ctnno.prop = ctnno; //箱号
                this.headers.sealno.prop = sealno; //铅封
                this.headers.dlPortCode.prop = dlPortCode; //装/卸货港
                this.headers.destinationPort.prop =
                    this.boxInfo?.blnoInfo?.destinationPort; // 青岛目的港
                // this.headers.ctnApplyBeginTime.prop=this.boxInfo.vessels.ctnApplyBeginTime // 收箱开始时间
                this.headers.backTime.prop = backTime; // 返场时间
                this.headers.inputTime.prop = inputTime; // 提箱时间
                this.headers.cargoTime.prop = cargoTime; // 集港时间
                this.headers.type.prop = (size || "") + (type || ""); //箱型
                this.headers.ctnOwner.prop = ctnOwner; //箱主
                var boxStatus = status;
                if (boxStatus == "F") {
                    var boxStatus = "重箱";
                } else if (boxStatus == "L") {
                    var boxStatus = "拼箱";
                } else if (boxStatus == "E") {
                    var boxStatus = "空箱";
                }
                this.headers.boxStatus.prop = boxStatus; //箱状态
                this.headers.packageNum.prop = packageNum; //件数

                this.headers.measure.prop = measure || volume; //体积
                // 是否是非宁波
                const isSHQD = this.showTab !== "NB" && this.showTab !== "XM";
                if (isSHQD) {
                    this.headers.measure.prop = volume; //上海体积
                    this.headers.packageNum.prop = packno; //件数
                    this.headers.dlPortCode.prop =
                        (loadPort || "") + "/" + (dischargePort || ""); //装/卸货港
                    if (this.headers.dlPortCode.prop === "/") {
                        this.headers.dlPortCode.prop = "";
                    }
                    this.headers.passFlag.prop = portPassFlag; //码头放行状态
                }

                // 天津
                if (this.showTab === "TJ") {
                    this.headers.blnoMain.prop = blnoMain;
                }

                if (this.showTab === "XM") {
                    this.headers.isPortPass.prop =
                        passFlag2StringXM(isPortPass); // 码头放行状态
                    this.headers.portPassTime.prop = portPassTime; // 码头放行时间
                    this.headers.isCustomPass.prop =
                        passFlag2StringXM(isCustomPass); // 海关放行状态
                    this.headers.customPassTime.prop = customPassTime; // 海关放行时间
                    this.headers.outGateTime.prop = outGateTime; // 出门时间
                }

                this.headers.weight.prop = weight; //货重

                this.headers.grossWeight.prop = isSHQD
                    ? totalWeight
                    : grossWeight; //箱货重
                this.headers.costcoTime.prop = costcoTime; //预录入时间
                this.headers.inGateTime.prop = isSHQD ? inPortTime : inGateTime; //进场时间
                this.headers.receiveTime.prop = receiveTime; //码头放行时间
                this.headers.cpcodeFlag.prop = cpcodeFlag; //码头运抵报告
                this.headers.loadTime.prop = loadTime; //装船时间
                if (this.showTab === "NB") {
                    var box_message_list = getString2JsonArr(bills);

                    box_message_list.forEach((item) => {
                        if (item && item.blno === this.boxInfo.blno) {
                            var packageNum = item.packageNum; //件数
                            var measure =
                                item.measure.substr(0, 1) === "."
                                    ? "0" + item.measure
                                    : item.measure; //体积
                            var weight = item.weight; //货重
                            this.headers.boxStatus.prop = boxStatus; //箱状态
                            this.headers.packageNum.prop = packageNum; //件数
                            this.headers.measure.prop = measure; //体积
                            this.headers.weight.prop = weight; //货重
                        }
                    });

                    //进场出场需要判断
                    this.headers.truckLicense.prop = truckLicense; //进场集卡
                    if (box.npp !== null) {
                        console.log(box.npp, "==");
                        this.headers.passFlag.prop = passFlag2String(
                            box.npp.passFlag,
                        ); //码头放行状态
                        this.receiveTime = box.npp.receiveTime;
                        this.headers.receiveTime.prop = this.receiveTime; //码头放行时间
                        this.headers.cpcodeFlag.prop = box.npp.cpcodeFlag; //码头运抵报告
                    }
                    // 集卡信息，暂时不用
                    // if (ctnno && this.takeoutList.length) {
                    // let curTakeoutInfo;
                    //     this.takeoutList.forEach((item) => {
                    //         if (item.ctnno === curCtnNo) {
                    //             curTakeoutInfo = item;
                    //         }
                    //     });
                    //     this.headers.outdoor_time.prop =
                    //         curTakeoutInfo.outdoor_time;
                    //     this.headers.yardname.prop = curTakeoutInfo.yardname;
                    // }
                }
            }
        },
        //计算累计总数
        showTotalNum() {
            if (this.showTab === "NB") {
                if (this.boxInfo.nppInGateEmptyVoList) {
                    var totalWt = 0;
                    var boxCount = 0; //总箱数
                    var packageCount = 0; //总件数
                    var weightCount = 0; //总货重
                    var errorValue = 0; //毛重误差
                    //预配舱单信息
                    if (this.boxInfo.manifest) {
                        totalWt = this.boxInfo.manifest.totalGrossWt;
                    }
                    (this.boxInfo.nppInGateEmptyVoList || []).forEach(
                        (element) => {
                            if (element && element.ctn) {
                                boxCount++;
                                var box_message_list = getString2JsonArr(
                                    element.ctn.bills,
                                );
                                box_message_list.forEach((item) => {
                                    if (
                                        item &&
                                        item.blno === this.boxInfo.blno
                                    ) {
                                        var packageNum = item.packageNum; //件数
                                        var weight = item.weight; //货重
                                        packageCount += Number(packageNum);
                                        weightCount += Number(weight);
                                    }
                                });
                            }
                        },
                    );
                    if (totalWt && weightCount) {
                        errorValue = Math.abs(
                            (totalWt / weightCount) * 100 - 100,
                        ).toFixed(2);
                        if (errorValue >= 5) {
                            this.errorFlag = 1;
                        }
                    }
                    this.headers.total.prop =
                        "集装箱数：" +
                        boxCount +
                        "， 件数：" +
                        packageCount +
                        "， 货重：" +
                        weightCount +
                        "， 毛重误差：" +
                        errorValue +
                        "%"; //累计总数：集装箱数：XX，件数：XXX，货重：XXX，毛重误差：XX%
                }
            } else {
                const ctnEmptyVoList = this.boxInfo.ctnEmptyVoList;
                const ctnInitialValue = {
                    packno: 0, // 总件数
                    weight: 0, // 总毛重
                };
                (ctnEmptyVoList || []).forEach((item) => {
                    ctnInitialValue.packno += Number(item.ctn.packno || 0);
                    ctnInitialValue.weight += Number(item.ctn.weight || 0);
                });
                this.headers.total.prop =
                    " 件数：" +
                    ctnInitialValue.packno +
                    "， 货重：" +
                    ctnInitialValue.weight;
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
p {
  margin: 0;
}

.detail_div {
  width:100%;
}

.bill_div {
  background: #fff;
  padding: 2rem;
  margin-bottom: 2rem;
  .billNo_div {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-content: center;
    align-items: center;
  }

  .billNo_info_div {
    position: relative;
  }
}

.title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}

.small_title {
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0;

  a {
    user-select: none;
  }
}

.info_title {
  display: block;
  width: fit-content;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0 0 1rem;

  a {
    user-select: none;
  }
}

.info_title:before {
  content: '';
  height: 2rem;
  margin-top: 12px;
  margin-right: 1rem;
  border-left: 2px solid #5572fd;
}

a {
  cursor: pointer;
  font-weight: 400;
  font-size: 1.4rem;
  color: rgb(85, 114, 253);
}

.box_div {
  background: #fff;
  padding: 2rem;
  margin-bottom: 2rem;
}
.back_div {
  background: #f1f3f6;
  border-radius: 4px;
  box-shadow: inset 0 0 15px rgba(55, 84, 170, 0), inset 0 0 20px rgba(255, 255, 255, 0), 10px 10px 18px rgba(55, 84, 170, 0.15), -10px -10px 22px white, inset 0px 0px 4px rgba(255, 255, 255, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  outline: none;
  border: none;
}
.back_div:hover .draw {
  opacity: 0.4;
}

.back_div:hover .prints {
  opacity: 1;
  transition: opacity ease-in-out 0.8s;
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
